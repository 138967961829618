<template>
  <section name="a15e" class="section section--body section--first section--last">
    <div class="section-content">
      <div class="section-inner sectionLayout--insetColumn"><h3 name="8024" id="8024" class="graf graf--h3 graf--leading graf--title">
        How to determine Instagram account geo location with 97% accuracy.</h3>
        <p name="ff29" id="ff29" class="graf graf--p graf-after--h3">Geographical location is one of the
          most important data points that characterize influencers and their audiences. In order for an
          advertising campaign to be effective, it must often target a particular country or even a city.
          How do we determine the geographic location of Instagram accounts at Klug?</p></div>
      <div class="section-inner sectionLayout--fullWidth">
        <figure name="df7a" id="df7a" class="graf graf--figure graf--layoutFillWidth graf-after--p">
          <div class="aspectRatioPlaceholder is-locked">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 50.2%;"></div>
            <img class="graf-image" data-image-id="1*fLBfebFghEgPpMLwbDKACw.png" data-width="1959" data-height="983" src="/img/articles/max_2560_1_fLBfebFghEgPpMLwbDKACw.png"></div>
        </figure>
      </div>
      <div class="section-inner sectionLayout--insetColumn">
        <figure name="e541" id="e541" class="graf graf--figure graf--layoutOutsetLeft graf-after--figure">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 525px; max-height: 938px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 155.7%;"></div>
            <img class="graf-image" data-image-id="1*noFIz0KbUVMnTrImY4HhWw.png" data-width="673" data-height="1048" src="/img/articles/max_600_1_noFIz0KbUVMnTrImY4HhWw.png"></div>
        </figure>
        <h3 name="5cd5" id="5cd5" class="graf graf--h3 graf-after--figure">Location tags</h3>
        <p name="fbdf" id="fbdf" class="graf graf--p graf-after--h3">Some Instagram posts have information
          about the location where the post was made. This metadata is called location tags. This is the
          simplest and most obvious method for determining geolocation. At the top of the article there is
          a global heat map where colors are used to visualize the number of posts that were made from
          every location in the world. This map is based on location tags of more than 4 billion posts. It
          shows that the highest post density corresponds to places with a high population density: large
          cities and their suburbs as well as areas along major transport arteries. These maps can be
          created for all countries. For example, we created a map showing post density in the United
          Kingdom.</p>
        <p name="b9d5" id="b9d5" class="graf graf--p graf-after--p">Even though the location tags provide
          clear information they do not represent an ideal source of knowledge about where the account
          holder lives:</p>
        <ul class="postList">
          <li name="e9fa" id="e9fa" class="graf graf--li graf-after--p">The majority of users do not
            enable location tagging for their posts. In fact location tags are not provided for most
            posts on Instagram (more than 85% lack them).
          </li>
          <li name="82f1" id="82f1" class="graf graf--li graf-after--li">Most often people start using
            location tagging only when they are traveling or visiting places that they find interesting.
            In other words, an influencer living in London may use location tags almost everywhere else
            in the world apart from London.
          </li>
        </ul>
        <p name="51a4" id="51a4" class="graf graf--p graf-after--li">If we determine location solely on the
          basis of location tags, then such a method will have low accuracy and limited coverage. We need
          to take advantage of additional information in order to more reliably determine the location of
          the account.</p>
        <h3 name="adf5" id="adf5" class="graf graf--h3 graf-after--p">Additional sources of
          information.</h3>
        <p name="9a18" id="9a18" class="graf graf--p graf-after--h3">Although it is not obvious at first
          glance, there are many additional sources of information that can be used to determine the
          influencer’s location. The first place is the text from the profile “bio”. It can specify the
          city of the influencer or local place names, the national domains of the user’s website or
          e-mail address, country flag emoji etc. The same information plus textual tags can be found in
          the body of posts and comments. In addition, it is also possible to take advantage of
          information about the language used in the post and audience’s geolocation. It is obvious, for
          example, that an influencer from Japan is more likely to have a Japanese audience than an
          influencer from Mexico. It is also unlikely that a Mexican influencer will write posts in
          Japanese. In addition, it is also possible to extract geo information from the photos that are
          included in posts, as long as the photo contains well-known landmarks.</p>
        <p name="d857" id="d857" class="graf graf--p graf-after--p">All of this information is extracted
          using a set of extremely complex data mining rules that also take advantage of pattern
          recognition and computer vision models. A description of the various information extraction
          techniques can be covered in several separate articles. We will go into more detail on this
          subject in future articles. Now we will focus on how the extracted geo information mentioned
          above can be used.</p>
        <h3 name="f1ca" id="f1ca" class="graf graf--h3 graf-after--p">Ensemble learning</h3>
        <p name="67c6" id="67c6" class="graf graf--p graf-after--h3">As we have several sources of geo
          information each separate source is not very reliable when considered on its own. However, when
          it is combined with several other sources it can provide a much more accurate result. The
          classic approach that is commonly used to work with multiple sources of information is called <a href="https://en.wikipedia.org/wiki/Ensemble_learning" data-href="https://en.wikipedia.org/wiki/Ensemble_learning" class="markup--anchor markup--p-anchor" rel="noopener" target="_blank">ensemble
            learning</a>.</p>
        <blockquote name="3d6d" id="3d6d" class="graf graf--blockquote graf-after--p"><em class="markup--em markup--blockquote-em">Ensemble learning is a machine learning paradigm
          where multiple learners are trained to solve the same problem. In contrast to ordinary machine
          learning approaches, which try to learn one hypothesis from training data, </em><a href="https://cs.nju.edu.cn/zhouzh/zhouzh.files/publication/springerEBR09.pdf" data-href="https://cs.nju.edu.cn/zhouzh/zhouzh.files/publication/springerEBR09.pdf" class="markup--anchor markup--blockquote-anchor" rel="noopener" target="_blank"><em class="markup--em markup--blockquote-em">ensemble methods</em></a><em class="markup--em markup--blockquote-em"> try to construct a set of hypotheses and then to
          combine them</em>.
        </blockquote>
        <p name="5bfd" id="5bfd" class="graf graf--p graf-after--blockquote">In our particular case we will
          use a type of ensemble learning that is called <a href="https://en.wikipedia.org/wiki/Ensemble_learning#Stacking" data-href="https://en.wikipedia.org/wiki/Ensemble_learning#Stacking" class="markup--anchor markup--p-anchor" rel="noopener" target="_blank">stacking</a>.</p>
        <figure name="d8df" id="d8df" class="graf graf--figure graf-after--p">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 700px; max-height: 596px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 85.1%;"></div>
            <img class="graf-image" data-image-id="1*euYVg06B1IM1N9EDncF59Q.png" data-width="1026" data-height="873" src="/img/articles/max_800_1_euYVg06B1IM1N9EDncF59Q.png"></div>
        </figure>
        <p name="6e50" id="6e50" class="graf graf--p graf-after--figure">This method works on the basis of
          two levels of models. The first level consists of models that extract geo information from the
          Instagram data that we mentioned in the previous section (base classifiers). The second level
          (meta-classifier) uses the predictions that are generated by the models at the first level to
          make a final decision.</p>
        <h3 name="3a10" id="3a10" class="graf graf--h3 graf-after--p">Weighted majority voting&nbsp;model</h3>
        <p name="0309" id="0309" class="graf graf--p graf-after--h3">The simplest way of creating a meta
          classifier is to use weighted majority voting. Each base classifier from the set [1…<em class="markup--em markup--p-em">L</em>] is assigned a weight <em class="markup--em markup--p-em">v</em>, which corresponds to the probability that the
          classification is correct <em class="markup--em markup--p-em">p </em>(in our case, that the
          influencer’s country was correctly determined). Probabilities are calculated on the basis of a
          training set.</p>
        <figure name="68b8" id="68b8" class="graf graf--figure graf-after--p">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 700px; max-height: 97px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 13.900000000000002%;"></div>
            <img class="graf-image" data-image-id="1*mRX5L87Sp5RpFAOJPluaqA.png" data-width="777" data-height="108" src="/img/articles/max_800_1_mRX5L87Sp5RpFAOJPluaqA.png"></div>
        </figure>
        <p name="5288" id="5288" class="graf graf--p graf-after--figure">Classifiers that are often
          incorrect receive less weight, whereas classifiers that are highly accurate are assigned greater
          weight.</p>
        <p name="5776" id="5776" class="graf graf--p graf-after--p">We then assign labels <em class="markup--em markup--p-em">S1,…,SL </em>(in this case, the probable countries) from
          each of the <em class="markup--em markup--p-em">L </em>base classifiers for each account that it
          can be applicable to, and we calculate a score for each class</p>
        <figure name="2d0a" id="2d0a" class="graf graf--figure graf-after--p">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 237px; max-height: 53px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 22.400000000000002%;"></div>
            <img class="graf-image" data-image-id="1*tZIMuwnyFRgFmvGhjvmk5A.png" data-width="237" data-height="53" src="/img/articles/max_800_1_tZIMuwnyFRgFmvGhjvmk5A.png"></div>
        </figure>
        <figure name="34bf" id="34bf" class="graf graf--figure graf-after--figure">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 312px; max-height: 129px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 41.3%;"></div>
            <img class="graf-image" data-image-id="1*KAZs2bTksWzm6FMAyfmE9Q.png" data-width="312" data-height="129" src="/img/articles/max_800_1_KAZs2bTksWzm6FMAyfmE9Q.png"></div>
        </figure>
        <p name="9e01" id="9e01" class="graf graf--p graf-after--figure">The label that is assigned is the
          one that received the highest score:</p>
        <figure name="5b02" id="5b02" class="graf graf--figure graf-after--p">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 332px; max-height: 84px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 25.3%;"></div>
            <img class="graf-image" data-image-id="1*Bxp1s-tJd7zUwxx4Y_QEtg.png" data-width="332" data-height="84" src="/img/articles/max_800_1_Bxp1s-tJd7zUwxx4Y_QEtg.png"></div>
        </figure>
        <p name="8882" id="8882" class="graf graf--p graf-after--figure">Our first model for determining geo
          location used this particular method, since it was proved to be the simplest to implement.
          However, it has a lot of shortcomings:</p>
        <ul class="postList">
          <li name="ee70" id="ee70" class="graf graf--li graf-after--p">Predictions of base classifiers
            may be incorrect. For example, the country of Japan may be extracted from the bio, but the
            location tags may indicate Brazil and Germany while at the same time the influencer actually
            lives in the United Kingdom. Therefore, the meta classifier should not just predict the
            country. It should also indicate the probability that the answer is correct. If the
            calculated probability falls below a certain threshold, then we are forced to conclude that
            we do not have enough information to determine the country. The majority of voting does not
            provide an explicit probability value, it only allows you to estimate the lower and upper
            bounds.
          </li>
          <li name="da87" id="da87" class="graf graf--li graf-after--li">Majority voting is not able to
            work with additional information. For example, it is not a class label like the account
            language.
          </li>
          <li name="53f5" id="53f5" class="graf graf--li graf-after--li">Majority voting can only work
            with scalar labels, while in real life most labels are discrete distributions. For example,
            location tags usually specify more than one country.
          </li>
        </ul>
        <p name="c893" id="c893" class="graf graf--p graf-after--li">Given all these shortcomings, we
          decided not to use majority voting and developed a new, more advanced model using <a href="https://medium.com/mlreview/gradient-boosting-from-scratch-1e317ae4587d" data-href="https://medium.com/mlreview/gradient-boosting-from-scratch-1e317ae4587d" class="markup--anchor markup--p-anchor" target="_blank">Gradient Boosting</a>.</p>
        <h3 name="81a1" id="81a1" class="graf graf--h3 graf-after--p">Gradient boosting&nbsp;model</h3>
        <p name="b1d6" id="b1d6" class="graf graf--p graf-after--h3">We developed a training, validation and
          test datasets based on information from Twitter. The owner of the Twitter account can specify
          their place of residence, which is publicly accessible. If the same person has an Instagram
          account, then we can use geo information from Twitter as a source of ground truth. In fact, geo
          data from Twitter does not represent absolute truth, since people do not always indicate their
          true place of residence. According to our estimates, the location information for approximately
          1–3% of Twitter accounts is inaccurate or outdated. However, as practice has shown, this does
          not inhibit machine learning.</p>
        <p name="a0f1" id="a0f1" class="graf graf--p graf-after--p">Gradient boosting has remained one of
          the most powerful machine learning algorithms for tabular data that is still in current use.
          Therefore, the quality of the obtained model was excellent and exceeded our expectations.</p>
        <p name="1c03" id="1c03" class="graf graf--p graf-after--p">We obtained an accuracy of <strong class="markup--strong markup--p-strong">97%</strong> with a coverage of <strong class="markup--strong markup--p-strong">86%</strong>. In other words, for 14% of Instagram
          accounts the model decided that there was not enough information to make a reliable decision,
          this gave a probability of less than the specified threshold. If a probability threshold is not
          used at all, and you take into account the predictions for any accounts that have at least some
          kind of geo information, you will get accuracy of <strong class="markup--strong markup--p-strong">93.4</strong>%. The accuracy was defined on the
          basis of a test set, i.e. accounts that the model never previously analyzed during the learning
          process and hyperparameter tuning.</p>
        <p name="b1c6" id="b1c6" class="graf graf--p graf-after--p">The graph of the relationship between
          accuracy and coverage for different probability threshold values is presented below:</p>
        <figure name="56c3" id="56c3" class="graf graf--figure graf-after--p">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 700px; max-height: 493px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 70.39999999999999%;"></div>
            <img class="graf-image" data-image-id="1*k6Gpfi3_LjusFGcRYO1tmw.png" data-width="1126" data-height="793" src="/img/articles/max_800_1_k6Gpfi3_LjusFGcRYO1tmw.png"></div>
        </figure>
        <p name="9690" id="9690" class="graf graf--p graf-after--figure">Let’s see what input data is the
          most important for making predictions:</p>
        <figure name="9a9d" id="9a9d" class="graf graf--figure graf-after--p">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 700px; max-height: 442px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 63.2%;"></div>
            <img class="graf-image" data-image-id="1*HjLlLAv658bZUO6h5TZnZQ.png" data-width="1328" data-height="839" src="/img/articles/max_800_1_HjLlLAv658bZUO6h5TZnZQ.png"></div>
        </figure>
        <p name="b7d7" id="b7d7" class="graf graf--p graf-after--figure">As can be seen from the graph, the
          country_id (country identifier, which allows the model to take into account the individual
          characteristics of various countries), location tags from posts, influencer language and
          audience geo data are the most important. This graph does not completely or accurately reflect
          the real significance of each datapoint, as not all datapoints are present in every account. If
          you normalize importance of datapoint by its frequency, you get a different picture in which the
          datapoints with the most accurate geo information will be assigned the most weight:</p>
        <figure name="1577" id="1577" class="graf graf--figure graf-after--p">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 700px; max-height: 442px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 63.2%;"></div>
            <img class="graf-image" data-image-id="1*FbxfT58Nv_Pq5DyuGBRXVQ.png" data-width="1253" data-height="792" src="/img/articles/max_800_1_FbxfT58Nv_Pq5DyuGBRXVQ.png"></div>
        </figure>
        <p name="04de" id="04de" class="graf graf--p graf-after--figure">You can see that the most accurate
          information is derived from phone numbers (country code), location tags and emojis.</p>
        <p name="c5bb" id="c5bb" class="graf graf--p graf-after--p">We can investgate even deeper and
          analyze our model using a SHAP <a href="https://arxiv.org/abs/1705.07874" data-href="https://arxiv.org/abs/1705.07874" class="markup--anchor markup--p-anchor" rel="noopener" target="_blank">framework</a>. First, let’s look at the
          detailed graph showing the importance of datapoints:</p>
        <figure name="1822" id="1822" class="graf graf--figure graf-after--p">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 700px; max-height: 852px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 121.8%;"></div>
            <img class="graf-image" data-image-id="1*2YSaqop_BfAOJ21m0dES_Q.png" data-width="1388" data-height="1690" src="/img/articles/max_800_1_2YSaqop_BfAOJ21m0dES_Q.png"></div>
        </figure>
        <p name="1814" id="1814" class="graf graf--p graf-after--figure">It can be seen that all of the
          datapoints that are shown in the graph work well for non-zero values, those that have a SHAP
          value that is markedly different from 0. Zero values mean that the feature was absent from the
          input data and they are shown in the graph using blue (low datapoint values). For lang_conf, the
          opposite is true, because the standard value of this datapoint is close to 1, and the near-zero
          values are an exception.</p>
        <p name="9281" id="9281" class="graf graf--p graf-after--p">Also, here are some datapoint importance
          charts for individual accounts from the test set:</p>
        <figure name="9ada" id="9ada" class="graf graf--figure graf-after--p">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 700px; max-height: 78px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 11.1%;"></div>
            <img class="graf-image" data-image-id="1*xdysvhneFv7NFeOCFzsLsA.png" data-width="1950" data-height="217" src="/img/articles/max_800_1_xdysvhneFv7NFeOCFzsLsA.png"></div>
        </figure>
        <figure name="29a9" id="29a9" class="graf graf--figure graf-after--figure">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 700px; max-height: 79px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 11.3%;"></div>
            <img class="graf-image" data-image-id="1*q-A-dUXA3aXazBnaeQrh0Q.png" data-width="1950" data-height="221" src="/img/articles/max_800_1_q-A-dUXA3aXazBnaeQrh0Q.png"></div>
        </figure>
        <figure name="60b7" id="60b7" class="graf graf--figure graf-after--figure">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 700px; max-height: 79px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 11.3%;"></div>
            <img class="graf-image" data-image-id="1*j-MtYW3TWtF7_ZCz4JPS7Q.png" data-width="1950" data-height="221" src="/img/articles/max_800_1_j-MtYW3TWtF7_ZCz4JPS7Q.png"></div>
        </figure>
        <p name="62f1" id="62f1" class="graf graf--p graf-after--figure">You can also see from these charts
          that the most “active” features are country_id, geotags from posts, languages, and emoji.</p>
        <!-- <h3 name="6f6b" id="6f6b" class="graf graf--h3 graf-after--p">Prediction samples from the
          test&nbsp;dataset</h3>
        <p name="bce5" id="bce5" class="graf graf--p graf-after--h3">At Klug we follow the principles
          of complete openness in our work. Therefore, we are publishing the prediction results of our
          model on the test dataset, this will allow our customers to independently check the accuracy and
          find the specific cases where our model is mistaken or where it provided accurate predictions.
          We are providing a sample consisting of 2,000 results that can be downloaded at the following
          address: <a href="/articles/ml_data/geo_sample.csv.gz" data-href="/articles/ml_data/geo_sample.csv.gz" class="markup--anchor markup--p-anchor" rel="nofollow noopener" target="_blank">ml_data/geo_sample.csv.gz</a>
        </p>
        <p name="650b" id="650b" class="graf graf--p graf-after--p">The file consists of 5 columns:</p>
        <ul class="postList">
          <li name="eaec" id="eaec" class="graf graf--li graf-after--p"><strong class="markup--strong markup--li-strong">instagram</strong> — Instagram account
          </li>
          <li name="6841" id="6841" class="graf graf--li graf-after--li"><strong class="markup--strong markup--li-strong">twitter</strong> — matching Twitter account,
            which is the source of ground truth
          </li>
          <li name="b7d9" id="b7d9" class="graf graf--li graf-after--li"><strong class="markup--strong markup--li-strong">prediction</strong> — predicted country
          </li>
          <li name="f54c" id="f54c" class="graf graf--li graf-after--li"><strong class="markup--strong markup--li-strong">truth</strong> — ground truth country from
            Twitter
          </li>
          <li name="5a00" id="5a00" class="graf graf--li graf-after--li"><strong class="markup--strong markup--li-strong">probability</strong> — label probability, which
            can be interpreted as prediction confidence
          </li>
        </ul>
        <p name="1738" id="1738" class="graf graf--p graf-after--li">The file includes the results where the
          probability is exceeding 90%.</p>
        <p name="c9d0" id="c9d0" class="graf graf--p graf-after--p">As you can see in the sample data,
          Instagram vs Twitter matching is not perfect, so the real model accuracy is even higher than the
          measured 97%.</p> -->
        <h3 name="4ff7" id="4ff7" class="graf graf--h3 graf-after--p">Some interesting visualizations</h3>
        <p name="120c" id="120c" class="graf graf--p graf-after--h3">To conclude the article, let’s cover a
          couple of interesting visualizations of the geographical data of Instagram accounts that were
          obtained using the model described above.</p>
        <p name="807b" id="807b" class="graf graf--p graf-after--p">At the beginning of the article, we
          provided a heat map of the world that showed post density as calculated according to location
          tags. Now let’s create a similar map, but in this one we will calculate the number of Instagram
          accounts in each country of the world. Of course, we do not have any way of calculating the
          absolute number, because we do not have data about all of the accounts that exist on Instagram.
          Instead, we will take a fairly large sample and calculate what percentage of all accounts belong
          to each country.</p></div>
      <div class="section-inner sectionLayout--outsetColumn">
        <figure name="d891" id="d891" class="graf graf--figure graf--layoutOutsetCenter graf-after--p">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 1000px; max-height: 376px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 37.6%;"></div>
            <img class="graf-image" data-image-id="1*q5QhD0dV8GxMlH4TVEOIgw.png" data-width="1950" data-height="733" src="/img/articles/max_1000_1_q5QhD0dV8GxMlH4TVEOIgw.png"></div>
        </figure>
      </div>
      <div class="section-inner sectionLayout--insetColumn"><p name="9b2c" id="9b2c" class="graf graf--p graf-after--figure">The
        three leading countries in terms of number of accounts are: <em class="markup--em markup--p-em">USA</em>, <em class="markup--em markup--p-em">Brazil</em>,
        and <em class="markup--em markup--p-em">Indonesia</em>. The fewest accounts are found in the
        countries of Africa, <em class="markup--em markup--p-em">North Korea</em>, and <em class="markup--em markup--p-em">Greenland</em>.</p>
        <p name="856e" id="856e" class="graf graf--p graf-after--p">The resulting visualization is
          interesting, but it is obvious that the greater the population of the country, the more
          Instagram accounts that it will have. In order to eliminate this dependency on population, we
          can calculate the percentage of the world’s Instagram accounts per capita. Thus, we can
          determine the popularity of Instagram among the population of a given country.</p></div>
      <div class="section-inner sectionLayout--outsetColumn">
        <figure name="f009" id="f009" class="graf graf--figure graf--layoutOutsetCenter graf-after--p">
          <div class="aspectRatioPlaceholder is-locked" style="max-width: 1000px; max-height: 381px;">
            <div class="aspectRatioPlaceholder-fill" style="padding-bottom: 38.1%;"></div>
            <img class="graf-image" data-image-id="1*qghaNCSC3m2bi2m02vp7yA.png" data-width="1950" data-height="742" src="/img/articles/max_1000_1_qghaNCSC3m2bi2m02vp7yA.png"></div>
        </figure>
      </div>
      <div class="section-inner sectionLayout--insetColumn"><p name="dbc7" id="dbc7" class="graf graf--p graf-after--figure">Now we
        see a completely different picture. These are the five countries whose population love Instagram the
        most: <em class="markup--em markup--p-em">Cyprus</em>, <em class="markup--em markup--p-em">United
          Arab Emirates</em>, <em class="markup--em markup--p-em">Iceland</em>, <em class="markup--em markup--p-em">Qatar</em>, and <em class="markup--em markup--p-em">Malaysia</em>. These countries are shown using bright yellow
        because they are hard to distinguish on the map. Among the major countries, Instagram is the most
        popular in <em class="markup--em markup--p-em">Brazil</em>, <em class="markup--em markup--p-em">Australia</em>
        and the <em class="markup--em markup--p-em">USA</em>. Instagram is the least popular in Africa and
        Asia.</p>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "GeoLocation"
}
</script>
